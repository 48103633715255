define('ember-cli-meta-tags/components/head-tag', ['exports', 'ember-cli-meta-tags/templates/components/head-tag'], function (exports, _headTag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var reads = Ember.computed.reads;
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _headTag.default,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('tagName', this.get('headTag.type'));
    },


    // expected head tag attributes
    attributeBindings: ['href', 'target', 'charset', 'crossorigin', 'dir', 'hreflang', 'media', 'rel', 'rev', 'sizes', 'type', 'content', 'http-equiv', 'name', 'scheme', 'async', 'defer', 'src', 'property', 'itemprop'],
    href: reads('headTag.attrs.href'),
    target: reads('headTag.attrs.target'),
    charset: reads('headTag.attrs.charset'),
    crossorigin: reads('headTag.attrs.crossorigin'),
    dir: reads('headTag.attrs.dir'),
    hreflang: reads('headTag.attrs.hreflang'),
    media: reads('headTag.attrs.media'),
    rel: reads('headTag.attrs.rel'),
    rev: reads('headTag.attrs.rev'),
    sizes: reads('headTag.attrs.sizes'),
    type: reads('headTag.attrs.type'),
    content: reads('headTag.attrs.content'),
    'http-equiv': reads('headTag.attrs.http-equiv'),
    name: reads('headTag.attrs.name'),
    scheme: reads('headTag.attrs.scheme'),
    async: reads('headTag.attrs.async'),
    defer: reads('headTag.attrs.defer'),
    src: reads('headTag.attrs.src'),
    property: reads('headTag.attrs.property'),
    itemprop: reads('headTag.attrs.itemprop')

  });
});