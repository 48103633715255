define('ember-shepherd/services/tour', ['exports', 'ember-shepherd/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var observer = Ember.observer;
  var set = Ember.set;
  var isEmpty = Ember.isEmpty;
  var isPresent = Ember.isPresent;
  var Service = Ember.Service;
  var Evented = Ember.Evented;
  var run = Ember.run;
  exports.default = Service.extend(Evented, {
    // Configuration Options
    confirmCancel: false,
    confirmCancelMessage: null,
    defaults: {},
    disableScroll: false,
    errorTitle: null,
    isActive: false,
    messageForUser: null,
    modal: false,
    modalContainer: 'body',
    requiredElements: [],
    steps: [],

    willDestroy: function willDestroy() {
      (0, _utils.cleanupShepherdElements)();
      this.cleanup();
    },


    /**
     * Get the tour object and call back
     * @public
     */
    back: function back() {
      get(this, 'tourObject').back();
      this.trigger('back');
    },


    /**
     * Cancel the tour
     * @public
     */
    cancel: function cancel() {
      get(this, 'tourObject').cancel();
    },


    /**
     * Advance the tour to the next step and trigger next
     * @public
     */
    next: function next() {
      get(this, 'tourObject').next();
      this.trigger('next');
    },


    /**
     * Show a specific step, by passing its id
     * @param id The id of the step you want to show
     * @public
     */
    show: function show(id) {
      get(this, 'tourObject').show(id);
    },
    start: function start() {
      set(this, 'isActive', true);
      get(this, 'tourObject').start();
    },
    onTourStart: function onTourStart() {
      if (get(this, 'modal')) {
        var shepherdOverlay = document.createElement('div');
        shepherdOverlay.id = 'shepherdOverlay';
        var parent = document.querySelector(get(this, 'modalContainer'));
        parent.appendChild(shepherdOverlay);
      }
      if (get(this, 'disableScroll')) {
        disableScroll.on(window);
      }
      this.trigger('start');
    },


    /**
     * This function is called when a tour is completed or cancelled to initiate cleanup.
     * @param {string} completeOrCancel 'complete' or 'cancel'
     */
    onTourFinish: function onTourFinish(completeOrCancel) {
      set(this, 'isActive', false);
      this.cleanup();
      this.trigger(completeOrCancel);
    },


    /**
     * Cleanup the modal leftovers, like the overlay and highlight, so they don't hang around.
     * @private
     */
    cleanup: function cleanup() {
      if (get(this, 'disableScroll')) {
        disableScroll.off(window);
      }
      if (get(this, 'modal')) {
        var tour = get(this, 'tourObject');

        if (tour) {
          var steps = tour.steps;


          steps.map(function (step) {
            var stepElement = (0, _utils.getElementForStep)(step);

            if (step && step.options.attachTo && stepElement) {
              stepElement.style.pointerEvents = 'auto';
            }
          });
        }
        run('afterRender', function () {
          (0, _utils.removeElement)('#shepherdOverlay');
          (0, _utils.removeElement)('#highlightOverlay');

          var shepherdModal = document.querySelector('.shepherd-modal');

          if (shepherdModal) {
            shepherdModal.classList.remove('shepherd-modal');
          }
        });
      }
    },


    /**
     * Creates an overlay element clone of the element you want to highlight and copies all the styles.
     * @param step The step object that points to the element to highlight
     * @private
     */
    createHighlightOverlay: function createHighlightOverlay(step) {
      var _this = this;

      (0, _utils.removeElement)('#highlightOverlay');

      var currentElement = (0, _utils.getElementForStep)(step);

      if (currentElement) {
        var highlightElement = currentElement.cloneNode(true);

        highlightElement.setAttribute('id', 'highlightOverlay');
        document.body.appendChild(highlightElement);

        this.setComputedStylesOnClonedElement(currentElement, highlightElement);

        // Style all internal elements as well
        var children = currentElement.children;


        var clonedChildren = highlightElement.children;

        for (var i = 0; i < children.length; i++) {
          this.setComputedStylesOnClonedElement(children[i], clonedChildren[i]);
        }

        (0, _utils.setPositionForHighlightElement)({
          currentElement: currentElement,
          highlightElement: highlightElement
        });

        window.addEventListener('resize', function () {
          run.debounce(_this, _utils.setPositionForHighlightElement, {
            currentElement: currentElement,
            highlightElement: highlightElement
          }, 50);
        });
      }
    },


    /**
     * Set computed styles on the cloned element
     *
     * @method setComputedStylesOnClonedElement
     * @param element element we want to copy
     * @param clonedElement cloned element above the overlay
     * @private
     */
    setComputedStylesOnClonedElement: function setComputedStylesOnClonedElement(element, clonedElement) {
      var computedStyle = window.getComputedStyle(element, null);

      for (var i = 0; i < computedStyle.length; i++) {
        var propertyName = computedStyle[i];

        clonedElement.style[propertyName] = computedStyle.getPropertyValue(propertyName);
      }
    },


    /**
     * This wraps the cancel function in a confirm dialog
     * @param  {boolean} confirmCancel Whether to show the dialog or not
     * @param  {string} confirmCancelMessage The message to display
     * @param  {object} tourObject The tour object
     * @private
     */
    wrapCancelFunction: function wrapCancelFunction(confirmCancel, confirmCancelMessage, tourObject) {
      var cancelFunction = tourObject.cancel;

      if (confirmCancel) {
        var cancelMessage = confirmCancelMessage || 'Are you sure you want to stop the tour?';

        var newCancelFunction = function newCancelFunction() {
          var stopTour = window.confirm(cancelMessage);
          if (stopTour) {
            cancelFunction();
          }
        };
        tourObject.cancel = newCancelFunction;
      }
    },
    initialize: function initialize() {
      var defaults = get(this, 'defaults');

      var tourObject = new Shepherd.Tour({
        defaults: defaults
      });

      // Allow for confirm cancel dialog
      this.wrapCancelFunction(get(this, 'confirmCancel'), get(this, 'confirmCancelMessage'), tourObject);

      tourObject.on('start', run.bind(this, 'onTourStart'));
      tourObject.on('complete', run.bind(this, 'onTourFinish', 'complete'));
      tourObject.on('cancel', run.bind(this, 'onTourFinish', 'cancel'));
      set(this, 'tourObject', tourObject);
    },


    /**
     * Creates a button of the specified type, with the given classes and text
     *
     * @param type The type of button cancel, back, or next
     * @param classes Classes to apply to the button
     * @param text The text for the button
     * @param action The action to call
     * @returns {{action: *, classes: *, text: *}}
     * @private
     */
    makeButton: function makeButton(_ref) {
      var type = _ref.type,
          classes = _ref.classes,
          text = _ref.text,
          action = _ref.action;

      var builtInButtonTypes = ['back', 'cancel', 'next'];
      if (builtInButtonTypes.includes(type)) {
        action = run.bind(this, function () {
          this[type]();
        });
      } else {
        action = action || function () {};
      }
      return {
        action: action,
        classes: classes,
        text: text
      };
    },


    /**
     * Check if attachTo is an object, if it is, put element and on into a string,
     * if it is already a string, just return that string
     *
     * @param attachTo
     * @returns {*}
     * @private
     */
    normalizeAttachTo: function normalizeAttachTo(attachTo) {
      if (attachTo && typeof attachTo.element === 'string' && typeof attachTo.on === 'string') {
        return attachTo.element + ' ' + attachTo.on;
      } else {
        return attachTo;
      }
    },


    /**
     * Modulates the styles of the passed step's target element, based on the step's options and
     * the tour's `modal` option, to visually emphasize the element
     *
     * @param step The step object that attaches to the element
     * @private
     */
    popoutElement: function popoutElement(step) {
      var currentElement = (0, _utils.getElementForStep)(step);

      if (!currentElement) {
        return;
      }

      if (step.options.highlightClass) {
        currentElement.classList.add(step.options.highlightClass);
      }

      if (get(this, 'modal')) {
        currentElement.style.pointerEvents = 'none';

        if (step.options.copyStyles) {
          this.createHighlightOverlay(step);
        } else {
          (0, _utils.toggleShepherdModalClass)(currentElement);
        }
      }
    },


    /**
     * Observes the array of requiredElements, which are the elements that must be present at the start of the tour,
     * and determines if they exist, and are visible, if either is false, it will stop the tour from executing.
     * @private
     */
    requiredElementsPresent: function requiredElementsPresent() {
      var _this2 = this;

      var allElementsPresent = true;

      var requiredElements = get(this, 'requiredElements');

      if (isPresent(requiredElements)) {
        /* istanbul ignore next: also can't test this due to things attached to root blowing up tests */
        requiredElements.forEach(function (element) {
          var selectedElement = document.querySelector(element.selector);

          if (allElementsPresent && (!selectedElement || (0, _utils.elementIsHidden)(selectedElement))) {
            allElementsPresent = false;
            set(_this2, 'errorTitle', element.title);
            set(_this2, 'messageForUser', element.message);
          }
        });
      }
      return allElementsPresent;
    },


    // TODO: Figure out how to use a computed instead of an observer here
    /**
     * Create a tour object based on the current configuration
     * @private
     */
    stepsChange: observer('steps', function () {
      var _this3 = this;

      this.initialize();
      var steps = get(this, 'steps');

      var tour = get(this, 'tourObject');

      // Return nothing if there are no steps
      if (isEmpty(steps)) {
        return;
      }
      /* istanbul ignore next: also can't test this due to things attached to root blowing up tests */
      if (!this.requiredElementsPresent()) {
        tour.addStep('error', {
          buttons: [{
            text: 'Exit',
            action: tour.cancel
          }],
          classes: 'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',
          copyStyles: false,
          title: get(this, 'errorTitle'),
          text: [get(this, 'messageForUser')]
        });
        return;
      }

      steps.forEach(function (step, index) {
        var id = step.id,
            options = step.options;


        options.buttons = options.builtInButtons.map(_this3.makeButton, _this3);
        options.attachTo = _this3.normalizeAttachTo(options.attachTo);
        tour.addStep(id, options);

        // Step up events for the current step
        var currentStep = tour.steps[index];

        currentStep.on('before-show', function () {
          _this3.popoutElement(currentStep);
        });
        currentStep.on('hide', function () {
          // Remove element copy, if it was cloned
          var currentElement = (0, _utils.getElementForStep)(currentStep);

          if (currentElement) {
            if (currentStep.options.highlightClass) {
              currentElement.classList.remove(currentStep.options.highlightClass);
            }

            (0, _utils.removeElement)('#highlightOverlay');
          }
        });

        if (!currentStep.options.scrollToHandler) {
          currentStep.options.scrollToHandler = function (elem) {
            // Allow scrolling so scrollTo works.
            disableScroll.off(window);

            if (typeof elem !== 'undefined') {
              elem.scrollIntoView();
            }

            run.later(function () {
              if (get(_this3, 'disableScroll')) {
                disableScroll.on(window);
              }
            }, 50);
          };
        }
      });
    })
  });
});