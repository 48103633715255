define('ember-autosave/computed-autosave', ['exports', 'ember', 'ember-autosave/autosave-proxy'], function (exports, _ember, _emberAutosaveAutosaveProxy) {
  exports['default'] = computedAutosave;

  var _get = _ember['default'].get;
  var _set = _ember['default'].set;
  var computed = _ember['default'].computed;

  function computedAutosave() {
    var propertyName = undefined,
        options = undefined;

    if (typeof arguments[0] === 'string') {
      propertyName = arguments[0];
      options = arguments[1] || {};
    } else if (typeof arguments[0] === 'object') {
      options = arguments[0];
    }

    var computedArgs = {
      get: function get() {
        options.context = this;

        var content = undefined;
        if (propertyName) {
          content = _get(this, propertyName);
        }

        return _emberAutosaveAutosaveProxy['default'].create({ content: content }, options);
      },

      set: function set(key, value, proxy) {
        _set(proxy, 'content', value);
        return proxy;
      }
    };

    if (propertyName) {
      return computed(propertyName, computedArgs);
    } else {
      return computed(computedArgs);
    }
  }
});