define('ember-cli-cordova/utils/redirect', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.__esModule = true;

  exports.default = function (url) {
    if (window.location.href.indexOf('file://') > -1) {
      _ember.default.run.later(function () {
        window.location.replace(url);
      }, 50);
    }
  };
});