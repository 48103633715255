define('ember-autosave/autosave-proxy', ['exports', 'ember'], function (exports, _ember) {
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  var setProperties = _ember['default'].setProperties;
  var debounce = _ember['default'].run.debounce;
  var cancel = _ember['default'].run.cancel;
  var computed = _ember['default'].computed;

  var AutosaveProxy = _ember['default'].Object.extend({
    _pendingSave: null,
    _options: null,
    _content: null,

    content: computed('content', {
      get: function get() {
        return this._content;
      },

      set: function set(key, value) {
        flushPendingSave(this);
        this._content = value;
        return value;
      }
    }),

    setUnknownProperty: function setUnknownProperty(key, value) {
      var oldValue = _ember['default'].get(this._content, key);

      this.propertyWillChange(key);
      set(this._content, key, value);
      this.propertyDidChange(key);

      if (isConfiguredProperty(this._options, key) && oldValue !== value) {
        var saveDelay = this._options.saveDelay;
        this._pendingSave = debounce(this, save, this, saveDelay);
      }
    },

    unknownProperty: function unknownProperty(key) {
      return get(this._content, key);
    },

    willDestroy: function willDestroy() {
      flushPendingSave(this);
    }
  });

  AutosaveProxy.reopenClass({
    defaultOptions: {
      save: function save(model) {
        model.save();
      },

      saveDelay: 1000
    },

    config: function config(options) {
      this.options = options;
    },

    create: function create(attrs, localOptions) {
      // Default library options
      var options = _ember['default'].copy(this.defaultOptions);

      // Global custom config options
      setProperties(options, this.options);

      // Local custom config options
      setProperties(options, localOptions);

      attrs._options = options;

      if (attrs.content === undefined) {
        attrs.content = {};
      }

      var obj = this._super(attrs);

      return obj;
    }
  });

  function isConfiguredProperty(options, prop) {
    _ember['default'].assert("You can configure the `only` option or the `except` option, but not both", !(options.only && options.except));

    if (options.only) {
      return options.only.indexOf(prop) !== -1;
    } else if (options.except) {
      return options.except.indexOf(prop) === -1;
    } else {
      return true;
    }
  }

  function save(autosaveProxy) {
    var context = autosaveProxy._options.context;
    var saveOption = autosaveProxy._options.save;

    var saveFunction;
    if (typeof saveOption === 'function') {
      saveFunction = saveOption;
    } else {
      saveFunction = context[saveOption];
    }

    autosaveProxy._pendingSave = null;
    return saveFunction.call(context, autosaveProxy._content);
  }

  function flushPendingSave(autosaveProxy) {
    var pendingSave = autosaveProxy._pendingSave;
    if (pendingSave) {
      var context = pendingSave[0];
      var fn = pendingSave[1];

      // Cancel the pending debounced function
      cancel(autosaveProxy);

      // Immediately call the pending save
      return fn(context);
    }
  }

  function cancelPendingSave(autosaveProxy) {
    cancel(autosaveProxy._pendingSave);
  }

  exports['default'] = AutosaveProxy;
  exports.flushPendingSave = flushPendingSave;
  exports.cancelPendingSave = cancelPendingSave;
});