define('ember-cli-cordova/services/cordova', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.__esModule = true;


  // from https://cordova.apache.org/docs/en/4.0.0/cordova_events_events.md.html
  // use var because cordova/android was throwing errors re: const && strict mode
  var CORDOVA_EVENTS = _ember.default.A(['deviceready', 'pause', 'resume', 'backbutton', 'menubutton', 'searchbutton', 'startcallbutton', 'endcallbutton', 'volumedownbutton', 'volumeupbutton', 'batterycritical', 'batterylow', 'batterystatus', 'online', 'offline']);

  // the cordova service listens for cordova events emitted to the document,
  // and triggers the same events in emberland.
  //
  // subscribe to cordova events as such:
  //
  // ```javascript
  // export default MyEmberObject.extend({
  //   cordova: Ember.inject.service()
  //
  //   init: function() {
  //     cordova.on('resume', function() { console.log('i am resumed'); });
  //   }
  // });
  // ```
  /* jshint esnext:true */

  exports.default = _ember.default.Service.extend(_ember.default.Evented, {

    setEventTriggers: _ember.default.on('init', function () {
      var _this = this;

      CORDOVA_EVENTS.forEach(function (eventName) {
        _ember.default.$(document).on(eventName, function () {
          _this.trigger(eventName);
        });
      });
    })
  });
});