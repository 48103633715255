define('ember-cp-difference/computeds/difference', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;

  /**
    A computed property that returns the difference between two property keys.
  
    Example:
  
    ```javascript
    var ToDoList = Ember.Object.extend({
      total: Ember.computed.readOnly('todos.length'),
      withSubtasks: Ember.computed.filter('todos', (todo) => { return todo.get('hasSubtasks'); }),
      totalWithSubtasks: Ember.computed.readOnly('withSubtasks.length'),
      totalWithoutSubtasks: Ember.computed.difference('total', 'totalWithSubtasks'),
      differenceBetweenTotals: Ember.computed.difference('totalWithSubtasks', 'totalWithoutSubtasks', { absolute: true })
    });
  
    var todoList = ToDoList.create({
      todos: [
        { name: 'First task', hasSubtasks: true },
        { name: 'Second task', hasSubtasks: true },
        { name: 'Third task', hasSubtasks: false },
        { name: 'Fourth task', hasSubtasks: false }
        { name: 'Fifth task', hasSubtasks: false }
      ]
    });
  
    todoList.get('totalWithoutSubtasks'); // 3
    todoList.get('differenceBetweenTotals'); // 2
    ```
  
    @method difference
    @for Ember.computed
    @param {String} propertyKey1
    @param {String} propertyKey2
    @param {Object} options
    @return {Ember.ComputedProperty} computes the difference between propertyKey1 and propertyKey2
    @public
   */
  function difference(propertyKey1, propertyKey2) {
    var options = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

    options = _ember['default'].$.extend({}, { absolute: false }, options);
    var prop2IsNumeric = !isNaN(parseFloat(propertyKey2)) && isFinite(propertyKey2);

    _ember['default'].assert('propertyKey1 is required for Ember.computed.difference', propertyKey1 && propertyKey1.length > 0);
    _ember['default'].assert('propertyKey2 is required for Ember.computed.difference', propertyKey2 && (propertyKey2.length > 0 || prop2IsNumeric));

    var differenceFunc = function differenceFunc() {
      var otherVal = prop2IsNumeric ? propertyKey2 : get(this, propertyKey2);
      var diff = get(this, propertyKey1) - otherVal;

      return options.absolute ? Math.abs(diff) : diff;
    };

    var computed = prop2IsNumeric ? _ember['default'].computed(propertyKey1, differenceFunc) : _ember['default'].computed(propertyKey1, propertyKey2, differenceFunc);
    var args = prop2IsNumeric ? [propertyKey1] : [propertyKey1, propertyKey2];

    return computed.property.apply(computed, args);
  }

  exports['default'] = difference;
});