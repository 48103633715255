define('ember-cli-meta-tags/initializers/router-head-tags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var Router = Ember.Router;
  var get = Ember.get;
  var service = Ember.inject.service;
  function initialize() {
    Router.reopen({
      headTags: service(),

      didTransition: function didTransition() {
        get(this, 'headTags').collectHeadTags();
        this._super.apply(this, arguments);
      }
    });
  }

  exports.default = {
    name: 'router-head-tags',
    after: 'head-tags',
    initialize: initialize
  };
});