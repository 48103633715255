define('ember-cli-cordova/mixins/cordova-events', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.__esModule = true;
  exports.default = _ember.default.Mixin.create({
    cordova: _ember.default.inject.service(),

    subscribeToCordovaEvents: _ember.default.on('init', function () {
      var cordova = this.get('cordova'),
          onCordova = this.get('onCordova');

      _ember.default.keys(onCordova).forEach(function (key) {
        var func = _ember.default.get(onCordova, key);

        // subscribe to events
        if (func instanceof Array) {
          func.forEach(function (fn) {
            if (this._validateIsFunction(fn)) {
              cordova.on(key, this, fn);
            }
          }, this);
        } else {
          if (this._validateIsFunction(func)) {
            cordova.on(key, this, func);
          }
        }
      }, this);
    }),

    _validateIsFunction: function (func) {
      var isFunction = false;

      if (func instanceof Function) {
        isFunction = true;
      } else if (typeof func === 'string') {
        var fn = this.get(func);

        isFunction = fn instanceof Function;
      }

      return isFunction;
    }
  });
});