define('ember-cli-cordova/initializers/in-app-livereload', ['exports', 'ember-cli-cordova/utils/redirect'], function (exports, _redirect) {
  'use strict';

  exports.__esModule = true;
  exports.initialize = undefined;
  var initialize = exports.initialize = function (app, config) {
    var url = config.cordova.emberUrl || 'http://localhost:4200';
    return (0, _redirect.default)(url);
  };

  exports.default = {
    name: 'cordova:in-app-livereload',
    initialize: initialize
  };
});