define('ember-cli-cordova/mixins/controllers/nav-bar', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.__esModule = true;
  exports.default = _ember.default.Mixin.create({
    nav: {
      title: {},
      leftButton: {},
      rightButton: {}
    },

    actions: {
      leftButton: function () {
        var leftAction = this.get('nav.leftButton.action');

        if (leftAction) {
          leftAction();
        }
      },

      rightButton: function () {
        var rightAction = this.get('nav.rightButton.action');

        if (rightAction) {
          rightAction();
        }
      },

      resetNavBar: function () {
        this.set('nav', {
          title: {},
          leftButton: {},
          rightButton: {}
        });
      }
    }
  });
});