define('emberx-select-blockless/components/x-select-blockless', ['exports', 'ember', 'emberx-select/components/x-select', 'emberx-select-blockless/templates/components/x-select-blockless'], function (exports, _ember, _emberxSelectComponentsXSelect, _emberxSelectBlocklessTemplatesComponentsXSelectBlockless) {
  exports['default'] = _emberxSelectComponentsXSelect['default'].extend({
    layout: _emberxSelectBlocklessTemplatesComponentsXSelectBlockless['default'],

    /**
     * Auxiliary computed property that replaces `content.`
     * in `optionValuePath`.
     *
     * @private
     * @property _valuePath
     */
    _valuePath: _ember['default'].computed('optionValuePath', function () {
      var optionValuePath = this.get('optionValuePath');
      if (optionValuePath) {
        return optionValuePath.replace(/^content\.?/, '');
      }
      return null;
    }),

    /**
     * Alias to `value`.
     * This way we accept `value` or `selection` properties.
     *
     * @property selection
     */
    selection: _ember['default'].computed.alias('value'),

    /**
     * Alias to `prompt`.
     * This way we accept `prompt` or `placeholder` properties.
     *
     * @property placeholder
     */
    placeholder: _ember['default'].computed.alias('prompt'),

    /**
     * Auxiliary computed property that replaces `content.`
     * in `optionLabelPath`.
     *
     * @private
     * @property _labelPath
     */
    _labelPath: _ember['default'].computed('optionLabelPath', function () {
      var optionLabelPath = this.get('optionLabelPath');

      if (optionLabelPath) {
        return optionLabelPath.replace(/^content\.?/, '');
      }
      return null;
    }),

    /**
     * Auxiliary computed array that holds `content` array
     * values and their labels. Used only in the blockless version.
     *
     * @private
     * @property _optionValues
     */
    _optionValues: _ember['default'].computed('_labelPath', '_valuePath', 'content.[]', function () {
      var _this = this;

      var content = this.get('content') || [];
      return content.map(function (object) {
        var label;
        var value = object;
        var valuePath = _this.get('_valuePath');
        var labelPath = _this.get('_labelPath');

        if (valuePath) {
          value = _ember['default'].get(object, valuePath);
        }

        if (labelPath) {
          label = _ember['default'].get(object, labelPath);
        } else {
          label = value;
        }

        return {
          value: value,
          label: label
        };
      });
    })
  });
});