define('ember-cli-meta-tags/components/head-tags', ['exports', 'ember-cli-meta-tags/templates/components/head-tags'], function (exports, _headTags) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',
    headTags: A([]),
    layout: _headTags.default
  });
});